import image from '../images/icons/bootstrap.svg';

const hardskills = [
  {
    "name": "Bootstrap",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/bootstrap.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL2Jvb3RzdHJhcC5zdmciLCJpYXQiOjE2NDc5MjE2MjUsImV4cCI6MTk2MzI4MTYyNX0.jsQQTtayBcehQKbXL1JIOSoBqYkRoeoHPaTt01C-2YE",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Css3",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/css3.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL2NzczMuc3ZnIiwiaWF0IjoxNjQ3OTIxNjM5LCJleHAiOjE5NjMyODE2Mzl9.2jO1nl1vVzYHSo2K80HQEfRSE58iiekDFwVeQ1RiQtM",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Docker",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/docker.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL2RvY2tlci5zdmciLCJpYXQiOjE2NDc5MjE2NDUsImV4cCI6MTk2MzI4MTY0NX0.Kb6bXIJmtjvXfl0yahsKL2fASN0NnXM8ePf_5SWGHLM",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Git",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/git.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL2dpdC5zdmciLCJpYXQiOjE2NDc5MjE2NTQsImV4cCI6MTk2MzI4MTY1NH0.hOVJc4Y6RKrW8TQSh0wBW9TEnXh2kctjERsyMwFzjGk",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "GitHub",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/github.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL2dpdGh1Yi5zdmciLCJpYXQiOjE2NDc5MjE2NTksImV4cCI6MTk2MzI4MTY1OX0.6r5aBGBjm038LsiiESr6ChPj3UvDd0HUPj2PovFwVoE",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Html5",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/html5.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL2h0bWw1LnN2ZyIsImlhdCI6MTY0NzkyMTY2NiwiZXhwIjoxOTYzMjgxNjY2fQ.HvBCfiVKZ4HytGHGOhiQ65njxlxup_BEJ2GvEOAx-Fg",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Javascript",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/javascript.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL2phdmFzY3JpcHQuc3ZnIiwiaWF0IjoxNjQ3OTIxNjc0LCJleHAiOjE5NjMyODE2NzR9.F9VEC9D5ILuvTP9x815HNIAtLvjl92B-pIUw_bjZlCc",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "MySQL",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/mysql.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL215c3FsLnN2ZyIsImlhdCI6MTY0NzkyMTY4MSwiZXhwIjoxOTYzMjgxNjgxfQ.ouPJrBIZLsWEIFbO9-XNNT1juq8MG3BkvtadvqIBcIM",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Node.js",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/nodejs.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL25vZGVqcy5zdmciLCJpYXQiOjE2NDc5MjE2ODgsImV4cCI6MTk2MzI4MTY4OH0.L0Pxl29ga1dHxHhPPFKh2RPQe5IoKWW58SS5rR_Bxwo",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Php",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/php.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL3BocC5zdmciLCJpYXQiOjE2NDc5MjE2OTYsImV4cCI6MTk2MzI4MTY5Nn0.H_oO0UyMHXRJT-z8Rud2_9pLtweV0aVmFa4pH4QZM5U",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "React.js",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/react.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL3JlYWN0LnN2ZyIsImlhdCI6MTY0NzkyMTcwMiwiZXhwIjoxOTYzMjgxNzAyfQ.FYxDHLmDOf15DgP6k5b8hGGGeHHGMJqB96VednekcOs",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Redux",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/redux.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL3JlZHV4LnN2ZyIsImlhdCI6MTY0NzkyMTcxMywiZXhwIjoxOTYzMjgxNzEzfQ.gr_v2BXqZHcCE-m1GhOubzyRq_t_t5JGvl4agApaSSE",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Sass",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/sass.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL3Nhc3Muc3ZnIiwiaWF0IjoxNjQ3OTIxNzE5LCJleHAiOjE5NjMyODE3MTl9.jiAUgyUydI3Uji6l1Z4SgKRr0t7r02eL70MQsJ0qw3Y",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Tailwind Css",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/tailwindcss.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL3RhaWx3aW5kY3NzLnN2ZyIsImlhdCI6MTY0NzkyMTcyNiwiZXhwIjoxOTYzMjgxNzI2fQ.RlywbZ3fy6YIeq8aLr8eJGV5Q0JdOGLzi4N7IF33Qs0",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Typescript",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/typescript.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL3R5cGVzY3JpcHQuc3ZnIiwiaWF0IjoxNjQ3OTIxNzMzLCJleHAiOjE5NjMyODE3MzN9.CIAC-7yVsUeogGrcoQj5BvCy_OLwICT05zfUWQrwCJU",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "React Router",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/react-router.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL3JlYWN0LXJvdXRlci5zdmciLCJpYXQiOjE2NDc5MjE3MDksImV4cCI6MTk2MzI4MTcwOX0.-9awwXcYbT4ATdkmUPBKzgVeSZk5uyl_yUsJh7fQhb0",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Scrum",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/scrum.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL3NjcnVtLnN2ZyIsImlhdCI6MTY0NzkyMTc0NiwiZXhwIjoxOTYzMjgxNzQ2fQ.zo_wEkOBwTY7Pw38APVYvB1OcuVhi_x4FdQX_v-oN8M",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Kanban",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/kanban.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL2thbmJhbi5zdmciLCJpYXQiOjE2NDc5MjE3NTUsImV4cCI6MTk2MzI4MTc1NX0.zJGSXlw-_hDoayXEG16qs-tKs0uFFw6O9kMShYNMuzU",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Express.js",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/express.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL2V4cHJlc3Muc3ZnIiwiaWF0IjoxNjQ3OTIxODcyLCJleHAiOjE5NjMyODE4NzJ9.Yeb01JadaeayeFGU26xSuF5GYk22KJKL7JPYS6Kqbi4",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Jest",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/jest.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL2plc3Quc3ZnIiwiaWF0IjoxNjQ3OTIxNzg0LCJleHAiOjE5NjMyODE3ODR9.CQblF2O9LB-MS_1TZpyuHih_q7-SjRNjnfx47dEvgZA",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "React Testing Library",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/testing-library.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL3Rlc3RpbmctbGlicmFyeS5zdmciLCJpYXQiOjE2NDc5MjE3OTUsImV4cCI6MTk2MzI4MTc5NX0.YmF6l-VMxmNWPPzjK3ctcDJUzxlrK_NSoafi-ckGFlA",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Styled Components",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/styled-components.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL3N0eWxlZC1jb21wb25lbnRzLnN2ZyIsImlhdCI6MTY0NzkyMTgwMiwiZXhwIjoxOTYzMjgxODAyfQ.TVBquMzC4YS-egTjH7kEwHqEyd7F3NqI2wuzEEsrmpg",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Mocha.js",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/mochajs.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL21vY2hhanMuc3ZnIiwiaWF0IjoxNjQ3OTIxODA5LCJleHAiOjE5NjMyODE4MDl9.cRCwVwV0BCFnXAOS6j4vFAFGOpw4DyejMekONQvaJpE",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Chai.js",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/chaijs.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL2NoYWlqcy5zdmciLCJpYXQiOjE2NDc5MjE4MTgsImV4cCI6MTk2MzI4MTgxOH0.K9rwCt0HxAt5xrBIxcYOO0LSZ5NqVmRPDiiH_1bp8wo",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Sinon.js",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/sinonjs.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL3Npbm9uanMuc3ZnIiwiaWF0IjoxNjQ3OTIxODI5LCJleHAiOjE5NjMyODE4Mjl9.Wge9D1Ac7pcIPwLsoDfQjFuEOrXZ9-sXCde8YmAoUCE",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Sequelize.js",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/sequelizejs.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL3NlcXVlbGl6ZWpzLnN2ZyIsImlhdCI6MTY0NzkyMTc2NiwiZXhwIjoxOTYzMjgxNzY2fQ.VC1vrP02gDXNsM4rPmYUqWDars7GWgF6xpmjNmYLJ60",
    "description": "Tecnologia aprendida"
  },
  {
    "name": "JSON Web Tokens",
    "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/icons/jwt.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL2ljb25zL2p3dC5zdmciLCJpYXQiOjE2NDc5MjE4NTEsImV4cCI6MTk2MzI4MTg1MX0.Kj-Jj4LNACVX9Woy6GuDaI8aFwh7rRppbXzu0VIqijM",
    "description": "Tecnologia aprendida"
  },
];

export default hardskills;
