import image from '../images/projetos/code.png';

const PROJETO_DESTAQUE = ["Store Manager", "To do List", "Trybewarts", "SpotiTrybe", "Trybewallet",
"React Testing Library", "TrybeTunes", "Davsom", "Docker Todo List"];

export const PROJECTS_OFFLINE = [
  {
    "name": "Pixel Art",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/pixels-art.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL3BpeGVscy1hcnQucG5nIiwiaWF0IjoxNjQ3OTIxOTQ0LCJleHAiOjE5NjMyODE5NDR9.Q-orwjOS5qZv8wswYJA5d3MgWmYOFOcaElMjqKAetVw",
    "technologies": ["Html5", "Css3", "Javascript", "Git", "GitHub"],
    "description": "O projeto é um site de desenho, com algumas paletas de cores aleatórias que são geradas, podendo adicionar, remover ou alterar a cor das paletas conforme desejar, o quadro inicial é de 5 por 5, podendo chegar até 50 por 50 para fazer desenhos em pixel art da maneira que preferir.",
    "site": "/projects/pixel-art/",
    "rep": "https://GitHub.com/BicaBenedicto/pixel-art"
  },
  {
    "name": "To do List",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/to-do-list.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL3RvLWRvLWxpc3QucG5nIiwiaWF0IjoxNjQ3OTIxOTUzLCJleHAiOjE5NjMyODE5NTN9.lIQoidd787ip9pBNZ6D7Mv_6xEjUJrv0JVJrEFNwYMo",
    "technologies": ["Html5", "Css3", "Javascript", "Git", "GitHub"],
    "description": "O projeto é uma lista de tarefas simples, onde pode adicionar, remover, marca, riscar, excluir e mover itens, além de poder salvar os itens em seu navegador para sempre que precisar pode acessar e conferir a sua lista.",
    "site": "/projects/todo-list",
    "rep": "https://GitHub.com/BicaBenedicto/todo-list"
  },
  {
    "name": "Trybewarts",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/trybewarts.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL3RyeWJld2FydHMucG5nIiwiaWF0IjoxNjQ3OTIxOTYxLCJleHAiOjE5NjMyODE5NjF9.Q5mITrRytRX1Tb2XTWvEsgZ0jYbzdFKsR4x3PXoIGC8",
    "technologies": ["Html5", "Css3", "Javascript", "Git", "GitHub"],
    "description": "O projeto é um site de cadastro, onde você coloca as informações como nome, escola, tecnologias que deseja aprender, entre outras coisas e ao final é impresso uma carta com as informações.",
    "site": "/projects/trybewarts/",
    "rep": "https://GitHub.com/BicaBenedicto/trybewarts"
  },
  // {
  //   "name": "E-commerce",
  //   "image": eCommerce,
  //   "technologies": ["React.js", "Css3", "React Router", "Redux", "Node.js", "Typescript", "Express.js", "Git", "GitHub", "MySQL"],
  //   "description": "Projeto desenvolvido como conclusão do Bootcamp de Front-End Developer do banco Inter, com um front-end que realiza a comunicação ao back-end e é gerenciado por um banco de dados.",
  //   "site": "",
  //   "rep": "https://GitHub.com/BicaBenedicto/ecommerce",
  // },
  {
    "name": "Trybewallet",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/trybewallet.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL3RyeWJld2FsbGV0LnBuZyIsImlhdCI6MTY0NzkyMjAwMSwiZXhwIjoxOTYzMjgyMDAxfQ.eTVMOvfuu-KYhAEvaEHjDe3V48wMa8UYBJFGy8lg4w0",
    "technologies": ["React.js", "Css3", "React Router", "Redux", "Git", "GitHub"],
    "description": "O projeto é um site de cambio, utilizando uma API que informa os valores do cambio para Real, sendo possivel anotar os gastos em diversas moedas, e o valor é convertido para Real Brasileiro, ficando salvo em seu navegador e utilizando o cambio no momento atual que realizou a adição.",
    "site": "",
    "rep": "https://GitHub.com/BicaBenedicto/trybewallet"
  },
  {
    "name": "TrybeTunes",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/trybetunes.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL3RyeWJldHVuZXMucG5nIiwiaWF0IjoxNjQ3OTIyMDA3LCJleHAiOjE5NjMyODIwMDd9.RBuQDg50Kzu989cfYk9Cq9MogcOopSsuXEaxm3TGPm0",
    "technologies": ["React.js", "Css3", "React Router", "Git", "GitHub"],
    "description": "O projeto é um site de música, onde é possivel escutar os primeiros 30 segundos de suas músicas favoritas, é utilizado a API do Itunes, com um simulador de sistema de login, podendo favoritas as músicas que mais gosta.",
    "site": "",
    "rep": "https://GitHub.com/BicaBenedicto/trybetunes"
  },
  // {
  //   "name": "Trivia",
  //   "image": trivia,
  //   "technologies": ["React.js", "Css3", "React Router", "Redux", "Git", "GitHub"],
  //   "description": "O projeto é um jogo de perguntas e respostas, onde apenas 1 resposta é a correta, foi realizado em grupo de 5 pessoas utilizando uma API que traz as perguntas, ao iniciar o jogo você começa com o score de 0, e ao acertar as perguntas é somado a pontuação dependendo o nivel da pergunta (fácil, médio e díficil) e o tempo que levou para responder, ao final do jogo possui uma tele dos resultados informando a quantidade de acertos e a pontuação final, tambem possui uma tela de "Ranking" onde é possivel competir e ver quem acerta mais.",
  //   "site": "",
  //   "rep": "https://GitHub.com/BicaBenedicto/trivia-react-Redux",
  // },
  {
    "name": "Davsom",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/davsom.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL2RhdnNvbS5wbmciLCJpYXQiOjE2NDc5MjIwMTQsImV4cCI6MTk2MzI4MjAxNH0.MXA3FG_uRcbmyHClHDU_JHgQN5wNrOl3Y9FB3thGEW4",
    "technologies": ["React.js", "Css3", "Php"],
    "description": "Projeto realizado como trabalho freelancer de campanha publicitária de uma loja automotiva",
    "site": "https://davsom.com",
    "rep": ""
  },
  {
    "name": "SpotiTrybe",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/spotitrybe.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL3Nwb3RpdHJ5YmUucG5nIiwiaWF0IjoxNjQ3OTIyMDIwLCJleHAiOjE5NjMyODIwMjB9.sZbr4UjM_uIpBjPzCwSt1OBXPTw51L3Yy4GJEFprLRk",
    "technologies": ["Html5", "Css3", "Javascript", "Scrum", "Kanban", "Git", "GitHub"],
    "description": "Projeto realizado em grupo, utilizando metodologia Scrum e método Kanban, criação de site com API do Spotify, podendo selecionar músicas por categorias, pesquisar playlist ou álbuns e tocar os primeiros 30 segundos de sua música favorita (limitação da API)",
    "site": "/projects/spotitrybe/",
    "rep": "https://GitHub.com/BicaBenedicto/spotitrybe"
  },
  {
    "name": "Mistery Letter",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/mistery-letter.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL21pc3RlcnktbGV0dGVyLnBuZyIsImlhdCI6MTY0NzkyMjAyNiwiZXhwIjoxOTYzMjgyMDI2fQ.vRkjpxtWOfrlJg86pa6VXHzEZ9cN0Mfhu3OLTIf7fao",
    "technologies": ["Html5", "Css3", "Javascript", "Git", "GitHub"],
    "description": "Projeto que transforma frases em imagens coladas como recortes de jornal, utilizado para treinamento de Javascript, DOM e propriedades do Css",
    "site": "/projects/mistery-letter/",
    "rep": "https://GitHub.com/BicaBenedicto/mistery-letter"
  },
  {
    "name": "Lessons Learned",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/lessons-learned.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL2xlc3NvbnMtbGVhcm5lZC5wbmciLCJpYXQiOjE2NDc5MjIwMzMsImV4cCI6MTk2MzI4MjAzM30.HxZI9rOta-xiXuuKHb-Ir9Kalzpf44y3Ew7P7M7mzco",
    "technologies": ["Html5", "Css3", "Git", "GitHub"],
    "description": "Projeto de portfolio, uma página de apresentação sobre mim, utilizando Html e Css, quando iniciei o curso da Trybe.",
    "site": "/projects/lessons-learned/",
    "rep": "https://GitHub.com/BicaBenedicto/lessons-learned"
  },
  {
    "name": "Shopping Cart",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/shopping-cart.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL3Nob3BwaW5nLWNhcnQucG5nIiwiaWF0IjoxNjQ3OTIyMDQwLCJleHAiOjE5NjMyODIwNDB9.YeY9BhSweF3gseHQgE2m9z4KScGkWjK1oDlJPYK3SV4",
    "technologies": ["Html5", "Css3", "Javascript", "Git", "GitHub"],
    "description": "Projeto é um site utilizando html, css e Javascript, com requisição de api com o mercado livre, podendo ver produtos e adicionar ao carrinho.",
    "site": "/projects/shopping-cart/",
    "rep": "https://GitHub.com/BicaBenedicto/shopping-cart"
  },
  {
    "name": "Color Guess",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/color-guess.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL2NvbG9yLWd1ZXNzLnBuZyIsImlhdCI6MTY0NzkyMTkyMCwiZXhwIjoxOTYzMjgxOTIwfQ.lq4Iyyq8uQuRSz4xJm4VkFOWoMjxuo-3Ddi1dTvfXVY",
    "technologies": ["Html5", "Css3", "Javascript", "Git", "GitHub"],
    "description": "Projeto é um jogo de seleção de cores, onde é informado um código em RGB, e cores para selecionar a correta, adicionando pontos ao selecionar a cor certa e podendo aumentar a dificuldade.",
    "site": "/projects/color-guess/",
    "rep": "https://GitHub.com/BicaBenedicto/color-guess"
  },
  {
    "name": "Meme Generator",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/meme-generator.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL21lbWUtZ2VuZXJhdG9yLnBuZyIsImlhdCI6MTY0NzkyMjA1MywiZXhwIjoxOTYzMjgyMDUzfQ.vgf6-AYZd67JdEn0zxmOJ7Hy96UmguJzZbzfZ15837U",
    "technologies": ["Html5", "Css3", "Javascript", "Git", "GitHub"],
    "description": "Projeto é uma página para criação de memes, podendo fazer o upload de uma imagem e adicionar um texto nela.",
    "site": "/projects/meme-generator/",
    "rep": "https://GitHub.com/BicaBenedicto/meme-generator"
  },
  {
    "name": "Netflix Interface",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/netflix-interface.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL25ldGZsaXgtaW50ZXJmYWNlLnBuZyIsImlhdCI6MTY0NzkyMjA1OSwiZXhwIjoxOTYzMjgyMDU5fQ.LmMXoe7r6RGgvkevApOFpT1Kg_HdcNgsyMKK2rl3btE",
    "technologies": ["Html5", "Css3", "Javascript", "Git", "GitHub"],
    "description": "Projeto é uma página com a interface do Netflix, com aparência similar e utilizando API de filmes para os gêneros.",
    "site": "/projects/Netflix-Interface/",
    "rep": "https://GitHub.com/BicaBenedicto/Netflix-Interface"
  },
  {
    "name": "Docker Todo List",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/code.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL2NvZGUucG5nIiwiaWF0IjoxNjQ3OTIyMDY2LCJleHAiOjE5NjMyODIwNjZ9.hu07vKhYsVbVbARxa24BcT8pwnHgllWRRcNL6vO3U38",
    "technologies": ["Docker", "Git", "GitHub"],
    "description": "Projeto de unificação de Front-end, Back-end e testes, utilizando o Docker em aplicação já pronta.",
    "site": "",
    "rep": "https://GitHub.com/BicaBenedicto/Docker-todo-list"
  },
  {
    "name": "Jest",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/code.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL2NvZGUucG5nIiwiaWF0IjoxNjQ3OTIyMDY2LCJleHAiOjE5NjMyODIwNjZ9.hu07vKhYsVbVbARxa24BcT8pwnHgllWRRcNL6vO3U38",
    "technologies": ["Jest","Javascript", "Git", "GitHub"],
    "description": "Testes unitários para código já criado anteriormente com testes assíncronos e mockagem de resultados utilizando o Jest.",
    "site": "",
    "rep": "https://GitHub.com/BicaBenedicto/Jest"
  },
  {
    "name": "Js Unit Tests",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/code.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL2NvZGUucG5nIiwiaWF0IjoxNjQ3OTIyMDY2LCJleHAiOjE5NjMyODIwNjZ9.hu07vKhYsVbVbARxa24BcT8pwnHgllWRRcNL6vO3U38",
    "technologies": ["Jest","Javascript", "Git", "GitHub"],
    "description": "Códigos em Javascript completando pequenos desafios com testes unitários com implementação de testes unitários em códigos já feitos.",
    "site": "",
    "rep": "https://GitHub.com/BicaBenedicto/js-unit-tests"
  },
  {
    "name": "MySQL All for One",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/code.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL2NvZGUucG5nIiwiaWF0IjoxNjQ3OTIyMDY2LCJleHAiOjE5NjMyODIwNjZ9.hu07vKhYsVbVbARxa24BcT8pwnHgllWRRcNL6vO3U38",
    "technologies": ["MySQL", "Git", "GitHub"],
    "description": "Resolução de desafios em interação com banco de dados utilizando MySQL.",
    "site": "",
    "rep": "https://GitHub.com/BicaBenedicto/MySQL-all-for-one"
  },
  {
    "name": "MySQL One for All",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/code.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL2NvZGUucG5nIiwiaWF0IjoxNjQ3OTIyMDY2LCJleHAiOjE5NjMyODIwNjZ9.hu07vKhYsVbVbARxa24BcT8pwnHgllWRRcNL6vO3U38",
    "technologies": ["MySQL", "Git", "GitHub"],
    "description": "Resolução de desafios em interação, manipulação e criação de banco de dados utilizando MySQL.",
    "site": "",
    "rep": "https://GitHub.com/BicaBenedicto/MySQL-one-for-all"
  },
  {
    "name": "Playground Functions",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/code.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL2NvZGUucG5nIiwiaWF0IjoxNjQ3OTIyMDY2LCJleHAiOjE5NjMyODIwNjZ9.hu07vKhYsVbVbARxa24BcT8pwnHgllWRRcNL6vO3U38",
    "technologies": ["Javascript", "Git", "GitHub"],
    "description": "Códigos em Javascript completando diversos desafios.",
    "site": "",
    "rep": "https://GitHub.com/BicaBenedicto/playground-functions"
  },
  {
    "name": "React Testing Library",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/code.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL2NvZGUucG5nIiwiaWF0IjoxNjQ3OTIyMDY2LCJleHAiOjE5NjMyODIwNjZ9.hu07vKhYsVbVbARxa24BcT8pwnHgllWRRcNL6vO3U38",
    "technologies": ["Testing Library", "Git", "GitHub"],
    "description": "Realização de testes unitário com RTL em aplicação React já pronta.",
    "site": "",
    "rep": "https://GitHub.com/BicaBenedicto/react-testing-library"
  },
  {
    "name": "Store Manager",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/code.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL2NvZGUucG5nIiwiaWF0IjoxNjQ3OTIyMDY2LCJleHAiOjE5NjMyODIwNjZ9.hu07vKhYsVbVbARxa24BcT8pwnHgllWRRcNL6vO3U38",
    "technologies": ["Node.js", "Express.js", "Mocha.js", "Sinon.js", "Chai.js", "Jest", "Git", "GitHub", "MySQL"],
    "description": "Criação de API, utilizando Node.js e a biblioteca Express.js, para gerenciamento de vendas e produtos, com diversas rotas para o gerenciamento e validações. Com testes unitários",
    "site": "",
    "rep": "https://GitHub.com/BicaBenedicto/store-manager"
  },
  {
    "name": "Talker Manager",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/code.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL2NvZGUucG5nIiwiaWF0IjoxNjQ3OTIyMDY2LCJleHAiOjE5NjMyODIwNjZ9.hu07vKhYsVbVbARxa24BcT8pwnHgllWRRcNL6vO3U38",
    "technologies": ["Node.js", "Express.js", "Git", "GitHub", "MySQL"],
    "description": "Criação de API utilizando Node.js e Express.js com middlewares de validação e tratamento de erro e manipulação de arquivos e leitura.",
    "site": "",
    "rep": "https://GitHub.com/BicaBenedicto/talker-manager"
  },
  {
    "name": "Teste de conhecimento",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/code.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL2NvZGUucG5nIiwiaWF0IjoxNjQ3OTIyMDY2LCJleHAiOjE5NjMyODIwNjZ9.hu07vKhYsVbVbARxa24BcT8pwnHgllWRRcNL6vO3U38",
    "technologies": ["Javascript", "Git", "GitHub", "MySQL"],
    "description": "Questões de lógica e desafios diversos, manipulação de arquivos, tratamento, filtro, entre diversos outros",
    "site": "",
    "rep": "https://GitHub.com/BicaBenedicto/teste-de-conhecimento"
  },
  {
    "name": "Zoo Functions",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/code.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL2NvZGUucG5nIiwiaWF0IjoxNjQ3OTIyMDY2LCJleHAiOjE5NjMyODIwNjZ9.hu07vKhYsVbVbARxa24BcT8pwnHgllWRRcNL6vO3U38",
    "technologies": ["Jest","Javascript", "Git", "GitHub"],
    "description": "Códigos em Javascript completando pequenos desafios com testes unitários e funções",
    "site": "",
    "rep": "https://GitHub.com/BicaBenedicto/zoo-functions"
  },
  {
    "name": "Blogs API",
    image,
    // "image": "https://sveumdxbnahjywvugrjp.supabase.in/storage/v1/object/sign/portfolio-images/projects/code.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwb3J0Zm9saW8taW1hZ2VzL3Byb2plY3RzL2NvZGUucG5nIiwiaWF0IjoxNjQ3OTIyMDY2LCJleHAiOjE5NjMyODIwNjZ9.hu07vKhYsVbVbARxa24BcT8pwnHgllWRRcNL6vO3U38",
    "technologies": ["Node.js", "Express.js", "Sequelize.js", "JSON Web Tokens", "Git", "GitHub"],
    "description": "Back-end criação de API de Blog, com token e validação de tokens, criação de usuário, post, categorias, utilizando o Node.js, Express.js, Sequelize e JSON Web Tokens",
    "site": "",
    "rep": "https://GitHub.com/BicaBenedicto/blogs-api"
  },
];

export const FEATURED_PROJECTS = PROJECTS_OFFLINE.filter((item) => PROJETO_DESTAQUE.some((name) => item.name === name));
